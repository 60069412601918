const SalesRegionTooltips = {
  activePapp: "Switch on for this vendor to receive leads and be active on fertighaus",
  name: "Sales region name",
  vendor: "Vendor the sales region belongs to",
  contactGender: "Sales region contact's gender",
  contactFirstName: "Given name of sales region contact",
  contactLastName: "Surname of sales region contact",
  contactEmail: "Email address of sales region contact",
  handoverRegions: "ZIP codes in which sales agent offers its services",
  displayRegions: "ZIP codes for which sales agent is shown as an option on fertighaus",
  leadFeedbackReportRecipients: "Recipients of the monthly lead feedback report for this sales region",
  accountOwner: "Neuraum employee who is displayed in reports to the sales region",
}

export default SalesRegionTooltips;