<template>
  <div>

    <CCard>
      <CCardHeader color="info" text-color="white">
        Sales Region
        <CSpinner v-if="loading" color="info"/>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <CInput
                v-model="salesRegion.name"
                v-on:update:value="updateItem"
                :isValid="notNull"
                placeholder="Enter name"
                description="Name"
                :readonly="!allowEdit"
                v-c-tooltip="{content: tooltips.name? tooltips.name: '', delay: tooltipDelay}"
            />
          </CCol>
          <CCol sm="4">
            <multiselect
                :disabled="false"
                v-model="salesRegion.vendor"
                @input="updateItem"
                :options="this.vendors? this.vendors.data? this.vendors.data: []: []"
                :preselect-first="true"
                :show-labels="true"
                label="shortName"
                track-by="nid"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="false"
                select-label="Select"
                deselect-label="Required field"
                placeholder="Vendor"
                v-on:search-change="updateVendorQ"
            />
            <CRow
                v-c-tooltip="{content: tooltips.vendor? tooltips.vendor: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Vendor</small>
            </CRow>
          </CCol>
          <CCol sm="2">
            <CSwitch
                :disabled="!allowEdit"
                :checked.sync="salesRegion.activePapp"
                v-on:update:checked="updateItem"
                color="info"
                size="lg"
                variant="opposite"
                labelOn="Yes"
                labelOff="No"
                v-c-tooltip="{content: tooltips.activePapp? tooltips.activePapp: '', delay: tooltipDelay}"
            />
            <CRow
                v-c-tooltip="{content: tooltips.activePapp? tooltips.activePapp: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Active</small>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <FormDetailSection
        section="Contact"
        v-bind:showSection="showContact"
        v-on:toggle="toggleContact"
    />
    <CCollapse
        :show.sync="showContact"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">Contact Details</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="3">
              <CInput
                  v-model="salesRegion.contactFirstName"
                  v-on:update:value="updateItem"
                  placeholder="Enter first name"
                  description="First name"
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.contactFirstName? tooltips.contactFirstName: '', delay: tooltipDelay}"
                  @input="salesRegion.contactFirstName = $event !== '' ? $event : null"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                  v-model="salesRegion.contactLastName"
                  v-on:update:value="updateItem"
                  placeholder="Enter last name"
                  description="Last name"
                  :readonly="!allowEdit"
                  @input="salesRegion.contactLastName = $event !== '' ? $event : null"
                  v-c-tooltip="{content: tooltips.contactLastName? tooltips.contactLastName: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm="4">
              <CInput
                  v-model="salesRegion.contactEmail"
                  v-on:update:value="updateItem"
                  :isValid="validEmail"
                  placeholder="Enter email"
                  description="Email"
                  :readonly="!allowEdit"
                  @input="salesRegion.contactEmail = $event !== '' ? $event : null"
                  v-c-tooltip="{content: tooltips.contactEmail? tooltips.contactEmail: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm="2">
              <multiselect
                  :disabled="!allowEdit"
                  v-model="salesRegion.contactGender"
                  @input="updateItem"
                  :options="['m', 'f']"
                  :custom-label="(item) => this.genderLabels[item]"
                  :searchable="false"
                  :close-on-select="true"
                  :allow-empty="true"
                  placeholder="Select gender"
                  select-label="Select"
                  deselect-label="Remove"
              />
              <CRow v-c-tooltip="{content: tooltips.contactGender? tooltips.contactGender: '', delay: tooltipDelay}">
                <small class="form-text text-muted w-100">Gender</small>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

    </CCollapse>
    <FormDetailSection
        section="Branding"
        v-bind:showSection="showBranding"
        v-on:toggle="toggleBranding"
    />
    <CCollapse
        :show.sync="showBranding"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">Branding & Display Settings</CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader
                v-c-tooltip="{content: tooltips.displayRegions? tooltips.displayRegions: '', delay: tooltipDelay}"
            >
              <CRow>
                <CCol sm="2">
                  <CButton
                      v-if="allowEdit"
                      @click="handoverToDisplayRegions"
                      color="info"
                      variant="outline"
                      class="btn-sm"
                      v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
                  >Use Handover Regions
                  </CButton>
                </CCol>
                <CCol sm="8">
                  <div class="badge-pw">PW</div>
                  {{ salesRegion.displayRegions ? salesRegion.displayRegions.length : 0 }} Display
                  regions for PW
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country="salesRegion.vendor? salesRegion.vendor.operatesIn: null"
                  v-bind:postalCodesIn="salesRegion? salesRegion.displayRegions: []"
                  v-on:update-item="(e) => {salesRegion.displayRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader
                v-c-tooltip="{content: tooltips.displayRegions? tooltips.displayRegions: '', delay: tooltipDelay}"
            >
              <CRow>
                <CCol sm="2">
                  <CButton
                      v-if="allowEdit"
                      @click="handoverToMsDisplayRegions"
                      color="info"
                      variant="outline"
                      class="btn-sm"
                      v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
                  >Use Handover Regions
                  </CButton>
                </CCol>
                <CCol sm="8">
                  <div class="badge-ms">MS</div>
                  {{ salesRegion.msDisplayRegions ? salesRegion.msDisplayRegions.length : 0 }} Display
                  regions for MS
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country="salesRegion.vendor? salesRegion.vendor.operatesIn: null"
                  v-bind:postalCodesIn="salesRegion? salesRegion.msDisplayRegions: []"
                  v-on:update-item="(e) => {salesRegion.msDisplayRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>

    </CCollapse>
    <FormDetailSection
        section="Handover"
        v-bind:showSection="showHandover"
        v-on:toggle="toggleHandover"
    />
    <CCollapse
        :show.sync="showHandover"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">Handover Settings</CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader
                v-c-tooltip="{content: tooltips.handoverRegions? tooltips.handoverRegions: '', delay: tooltipDelay}"
            >
              <div class="badge-pw">PW</div>
              {{ salesAgent ? salesRegion.handoverRegions ? salesRegion.handoverRegions.length : 0 : 0 }}
              Handover regions for PW
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country="salesRegion? salesRegion.vendor? salesRegion.vendor.operatesIn: null: null"
                  v-bind:postal-codes-in="salesRegion? salesRegion.handoverRegions? salesRegion.handoverRegions: []: []"
                  v-on:update-item="(e) => {this.salesRegion? this.salesRegion.handoverRegions = e: null; this.updateItem();}"
              />
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader
                v-c-tooltip="{content: tooltips.handoverRegions? tooltips.handoverRegions: '', delay: tooltipDelay}"
            >
              <div class="badge-ms">MS</div>
              {{ salesAgent ? salesRegion.msHandoverRegions ? salesRegion.msHandoverRegions.length : 0 : 0 }}
              Handover regions for MS
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country="salesRegion? salesRegion.vendor? salesRegion.vendor.operatesIn: null: null"
                  v-bind:postal-codes-in="salesRegion? salesRegion.msHandoverRegions? salesRegion.msHandoverRegions: []: []"
                  v-on:update-item="(e) => {this.salesRegion? this.salesRegion.msHandoverRegions = e: null; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>

    </CCollapse>
    <FormDetailSection
        section="Reporting"
        v-bind:showSection="showReporting"
        v-on:toggle="toggleReporting"
    />
    <CCollapse
        :show.sync="showReporting"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">Reporting Settings</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="4">
              <multiselect
                  data-sel="slct-ao"
                  :disabled="!allowEdit"
                  v-model="salesRegion.accountOwner"
                  @input="updateItem"
                  :options="this.accountOwners? this.accountOwners.data? this.accountOwners.data: []: []"
                  :show-labels="true"
                  :customLabel="accountOwnerLabel"
                  track-by="nid"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="true"
                  select-label="Select"
                  placeholder="Select account owner"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.accountOwner? tooltips.accountOwner: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Account owner</small>
              </CRow>
            </CCol>
            <ReportRecipientAdd/>
            <CCol
                sm="2"
                class="text-left"
                v-if="allowEdit"
            >
              <CButton
                  color="info"
                  variant="outline"
                  @click="openAddRecipientModal"
              >
                New recipient
              </CButton>
            </CCol>
            <CCol :sm="allowEdit? 6: 8">
              <multiselect
                  data-sel="slct-lfrcpt"
                  class="multiselect-recipients"
                  :disabled="!allowEdit"
                  @input="updateItem"
                  v-model="salesRegion.leadFeedbackReportRecipients"
                  :options="this.reportRecipients? this.reportRecipients.data? this.reportRecipients.data: []: []"
                  :show-labels="true"
                  label="email"
                  track-by="email"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="false"
                  :allow-empty="true"
                  select-label="Add"
                  deselect-label="Remove"
                  placeholder="Add recipients"
                  v-on:search-change="updateReportRecipientQ"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.leadFeedbackReportRecipients? tooltips.leadFeedbackReportRecipients: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">Lead feedback report recipients</small>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

    </CCollapse>
  </div>
</template>

<script>
import gql from "graphql-tag";
import FormDetailSection from "@/components/common/FormDetailSection";
import PostalCodeSelector from "@/apps/partners/components/PostalCodeSelector";
import SalesRegionTooltips from "@/apps/partners/tooltips/sales_region_tooltips";
import ReportRecipientAdd from "@/apps/partners/components/ReportRecipientAdd";

const clonedeep = require('lodash.clonedeep')

const queryVendors = gql`
query vendors($q: String){
  vendors(q: $q, order: "name") {
  data {
    nid
    shortName
    operatesIn{
    nid
    name
    }
  }
  }
}
`
const queryReportRecipients = gql`
query reportRecipients($q: String){
  reportRecipients(q: $q, order: "email") {
  data {
    nid
    email
  }
  }
}
`
const queryAccountOwners = gql`
query {
  accountOwners(order: "lastName") {
  data{
    nid
    firstName
    lastName
  }
   }
}
`

export default {
  name: "SalesRegionForm",
  props: ["salesRegionIn"],
  components: {
    FormDetailSection,
    PostalCodeSelector,
    ReportRecipientAdd,
  },
  data() {
    return {
      tooltips: SalesRegionTooltips,
      tooltipDelay: 500,
      vendors: [],
      vendorQ: null,
      reportRecipients: [],
      reportRecipientQ: null,
      genderLabels: {f: 'female', m: 'male'},
      showContact: false,
      showBranding: false,
      showHandover: false,
      showReporting: false
    }
  },
  methods: {
    updateItem() {
      const data = clonedeep(this.salesRegion);
      //multiselect object
      data.vendor = this.salesRegion.vendor ? {nid: this.salesRegion.vendor.nid} : null;
      data.displayRegions = this.salesRegion.displayRegions ? this.salesRegion.displayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.msDisplayRegions = this.salesRegion.msDisplayRegions ? this.salesRegion.msDisplayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.handoverRegions = this.salesRegion.handoverRegions ? this.salesRegion.handoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.msHandoverRegions = this.salesRegion.msHandoverRegions ? this.salesRegion.msHandoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.accountOwner = this.salesRegion.accountOwner ? {nid: this.salesRegion.accountOwner.nid} : null;
      data.leadFeedbackReportRecipients = this.salesRegion.leadFeedbackReportRecipients ? this.salesRegion.leadFeedbackReportRecipients.map(item => {
        return {nid: item.nid}
      }) : [];
      this.$emit('update-item', data);
    },
    notNull(val) {
      return val ? val.length >= 1 : false
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    updateVendorQ(event) {
      this.vendorQ = event
    },
    updateReportRecipientQ(event) {
      this.reportRecipientQ = event
    },
    toggleContact() {
      this.showContact = !this.showContact
    },
    toggleBranding() {
      this.showBranding = !this.showBranding
    },
    toggleHandover() {
      this.showHandover = !this.showHandover
    },
    toggleReporting() {
      this.showReporting = !this.showReporting
    },
    handoverToDisplayRegions() {
      this.salesRegion.displayRegions = this.salesRegion.handoverRegions ? clonedeep(this.salesRegion.handoverRegions) : [];
      this.updateItem();
    },
    handoverToMsDisplayRegions() {
      this.salesRegion.msDisplayRegions = this.salesRegion.msHandoverRegions ? clonedeep(this.salesRegion.msHandoverRegions) : [];
      this.updateItem();
    },
    openAddRecipientModal() {
      this.$store.commit('set', ['addReportRecipientModalShow', true]);
    },
    accountOwnerLabel({firstName, lastName}) {
      return `${firstName} ${lastName}`
    },
  },
  apollo: {
    vendors: {
      query: queryVendors,
      variables() {
        return {
          q: this.$route.params.vendor_nid
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      }
    },
    accountOwners: {
      query: queryAccountOwners,
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      },
      skip() {
        return !this.allowEdit
      }
    },
    reportRecipients: {
      query: queryReportRecipients,
      variables() {
        return {
          q: this.reportRecipientQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      },
      skip() {
        return !this.allowEdit
      }
    }
  },
  computed: {
    salesRegion() {
      return this.salesRegionIn ? this.salesRegionIn : {}
    },
    loading() {
      return this.$apollo.queries.vendors.loading
    },
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set() {
      }
    },
  },
  watch: {
    salesRegionIn() {
      this.updateItem();
    },
    salesRegion() {
      this.updateItem();
    }
  }
}
</script>

<style scoped>
.multiselect {
  border-width: 2pt;;
  list-style-type: none;
  margin-bottom: 1pt;
}

.ms-row {
  margin-bottom: .75em;
}

.switch {
  margin-bottom: .2em;
  margin-top: .15em;
}

.multiselect-recipients {
  margin-bottom: 2pt;
  overflow: auto;
  height: auto;
}

.multiselect--active {
  border-width: 2pt;
  list-style-type: none;
  margin-bottom: 1pt;
  overflow: visible;
}
.badge-ms {
  background-color: #9c0725 !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pw {
  background-color: #0083aa !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-im {
  background-color: #fc0 !important;
  white-space: normal !important;
  color: #303038 !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
